@import './variables';
@import './layout';
@import './mixins';

$primary: $evc-color-teal;

@import 'customize-bootstrap.scss';
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import './spinner';

html, body {
	height: 100%;
	font-size:12px;
	margin: 0;
}

body {
	min-height: 100vh;
	min-height: -webkit-fill-available;
}

html, body { height: 100%; }

html, body { height: 100%; }
body { margin: 0;  }

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

body {
	font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif !important;
	font-size: 15px;
    background-color: white;
	color: #2d2e2f;
	touch-action: manipulation;
	position: relative;
}

th {
	text-align: left;
}

html {
	height: -webkit-fill-available;
}

.evcHome {
	background-size: cover;
	background-image:  url(/assets/images/evc_background.resource);
	background-repeat: no-repeat;
	height: 100vh;
	width: 100%;
	background-origin: border-box;
}

.sapHeader {
	background-color: white;
	padding: 1em;
	width: 100%;
	border-bottom: 1px solid $evc-color-teal;
}

.centerInput {
	text-align: center;
}

// button {
// 	font-weight: bold;
//   line-height: 1.5;
//   font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 1.5;
//   align-items: center;
//   border-radius: 4px;
//   border: 0;
//   cursor: pointer;
//   display: inline-flex;
//   font-size: 15px;
//   font-weight: bold;
//   height: 35px;
//   outline: none;
//   overflow: hidden;
//   __<<ngThemingMigrationEscapedComment0>>__
//   text-align: center;
//   text-decoration: none;
//   text-overflow: ellipsis;
//   user-select: none;
//   vertical-align: middle;
//   white-space: nowrap;
// }

// a,
// a:active,
// a:focus,
// a:hover,
// button,
// button:active,
// button:hover,
// .btn,
// .btn:active,
// .btn:hover {
// 	color: $evc-color-teal
// }

// a .fillButtonIcon svg,
// a:active .fillButtonIcon svg,
// a:hover .fillButtonIcon svg,
// button .fillButtonIcon svg,
// button:active .fillButtonIcon svg,
// button:hover .fillButtonIcon svg {
// 	fill: $evc-color-teal;
// }

.brand,
.brand:active,
.brand:hover {
	color: white;
	background: linear-gradient(135deg, #33bfcc, $evc-color-teal);

	border-color: none; /* $evc-color-teal; */
}

.brand:disabled {
    color: #ffffff;
	background: linear-gradient(135deg, #aee5ea, #ccedf0);
}

.evcBody {

	height: 100%;
	width: 100%;
	overflow: auto;

	display: flex;
	flex-direction: column;
	-webkit-flex: 5;
	-moz-flex: 5;
	-ms-flex: 5;
	flex: 5;
}

// .evcPageFooter {
//     border-top: 1px solid #2d2e2f;
// }

.complianceSlider .slds-slider-label__range,
.complianceSlider .slds-slider__value {
	display: none;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.complianceSlider .slds-slider__range::-webkit-slider-thumb {
	background: $evc-color-teal;
}

.complianceSlider label {
	display: none;
}

.evcHomeLink {
	background: transparent;
}

.evcHomeLink,
.evcHomeLink:hover {
	color: #33bfcc;
	box-shadow: none;
}

.evcHomeLink svg {
	color: #33bfcc;
    fill: $evc-color-teal;
}

.prodTitle {
	font-size: 26px;
	color: #2D2E2F;
	display: flex;
	flex-direction: row;
	background-color: #e5f6f7;
	padding: .5em;
}

.prodTitle .prodStep {
	font-weight: bold;
}

.prodBody {
	color: #2D2E2F;
	height: 100%;
	padding: 0 1em;
}

.prodBody .heading,
.evcBody .heading {
	font-size: 34px;
	font-weight: 300;
	line-height: 1;
	color: $spot-color-neutral-48;
}

.prodBody .body {
	font-size: 18px;
}

.brand,
.brand:hover,
.brand:active {
	color: white;
	background: linear-gradient(135deg, #33bfcc, $evc-color-teal);

	border-color: none; /* $evc-color-teal; */
}

.sales {
	background: #d8292f;
	color: #ffffff;
}

.prevButton {
	padding-right: 20px;
}

.teal {
	color: $evc-color-teal;
}

.orange {
	color: #F79d20;
}
.blue {
	color: #009fdb;
}
.green {
	color: #7fbc42;
}
.purple {
	color: #9c60a7;
}

.groupFooter {
    background-color: $evc-color-teal;
    color: white;
    text-align: center;
    padding: 5px;
}

.vbox {

	display: -webkit-flex;
	display:	-moz-flex;
	display:	 -ms-flex;
	display:		 flex;

	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.hbox {
	display: -webkit-flex;
	display:	-moz-flex;
	display:	 -ms-flex;
	display:		 flex;

	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.inputNumber {
	text-align: right;
}
.inputNumber input {
	text-align: right;
}

.addValue {
	text-align: right;
	/* padding: 10px; */
	/*border-color: $evc-color-teal;*/
	font-size: 18px;
}

.summaryPage h1 {
	font-size: 2em;
}

.summaryPage {
	height: 100%;
	width: 100%;
	border: none;
}

.summaryPage header {
	background-color: white;/* #f4f6f7; */
    padding: 0 5px 0 5px;
}

.activeProductNav {
	border-bottom: 2px solid $evc-color-teal;
    padding-bottom: 2px;
	color: $evc-color-teal;
	transform: scale(1.10);
}

.highlightSavings {
	border: 1px solid $evc-color-teal;
	border-radius: 5px;
	padding: 5px;
}

.highlightSavings2 {
	border-color: $evc-color-teal;
}

.runsBadge {
	float: right;
	font-size: .85em;
}

.evcSummaryTable {
	text-align: center;
	width: 98%;
	margin-left: auto;
	margin-right: auto;
}

.evcSummaryTable .underline {
	/* padding: 10px; */
	border-top: 1px solid #BABDBF;
}

.evcSummaryTable .prodRow,
.profitTable .prodRow {
	padding: 10px;
	border-top: 1px solid #BABDBF;
}

.profitTable .actSlider {
	width:100px;
}

.calcTable .calcValue,
.evcSummaryTable td {
	padding: 3px 5px;
}

.evcSummaryTable .headRow {
	vertical-align: bottom;
}

.evcSummaryTable th {
	padding: 3px 2px;
	color: #2d2e2f;
	font-size: 14px;
	font-weight: bold;
}

.prodItemTable td {
	border: unset;
	text-align: left;
}

.evcSummaryTable .prodTotal {
	color: #2d2e2f;
	font-weight: bold;
	font-size: 26px;
}

.evcSummaryTable .actName {
	font-size: 15px;
	font-weight: 300;
	text-align: center;
	line-height: 22.5px;
}
.evcSummaryTable .amount {
	text-align: right;
}

.evcSummaryTable .prodTotal2 {
	text-align: center;
}

.evcSummaryTable .prodTotal2 table {
	width: 100%;
	height: 100%;
}

.evcSummaryTable .prodTotal2 td {
	padding: 0px;
}

.headerLogo {
	height: 24px;
	margin: 24px;
	/* width: 100px; */
}

.prodSummary {
	width: 100%;
}

.prodSummary th {
	padding: 10px;
	font-size: 15px;
	font-weight: 300;
	color: #2D2E2F;
}

.prodSummary td {
	padding: 10px;
	font-weight: bold;
}

.highlightNumbers .slds-input,
.highlightNumbers input,
.highlightNumbers .slds-input[readonly],
.highlightNumbers {
	color: #2d2e2f;
	font-size: 24px;
	font-weight: bold;
	line-height: 36px;
}

.profitTable .actName,
.calcTable .actName {
	font-size: 18px;
	font-weight: 300;
}

.evcSummaryTable .prodName,
.prodSummary .prodName {
	font-size: 18px;
	font-weight: bold;
	color: #2D2E2F;
}

.prodSummary tr {
	padding: 10px;
}

.profitTable .inputCenter input,
.calcTable .calcValue input {
	text-align: center;
}

.profitTable .calcSymbol,
.evcSummaryTable .calcSymbol,
.calcTable .calcSymbol,
.prodSummary .calcSymbol {
	background: white;
	font-size: 36px;
	font-weight: bold;
	/* color: #E5F6F7; */
	color: #dbdadb;
	text-align: center;
}

.profitTable td {
	padding: 10px;
}

.profitTable th {
	padding: 0 10px;
}

.evcUtil {
	font-size: 34px;
	width: 150px;
	margin-right: auto;
	margin-left: auto;
}

.fillTeal svg {
	fill: $evc-color-teal;
}

.fillWhite svg {
	fill: white;
}

.smallIcon svg {
	width:35px;
	height:35px;
}

.smallIcon {
	width:35px !important;
	height:35px !important;
}

.mediumIcon svg {
	width:60px;
	height:60px;
//    transform: scale(2);
}
.mediumIcon {
	width:60px !important;
	height:60px !important;
}

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
td {
	font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif !important;
}

.video {
	font-size: 12px;
	padding-left: 10px;
	font-weight: bold;
}

.selectProducts {
	display: inline-flex;
	flex-flow:wrap;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.selectProducts .selectProductsColumn {
	align-self: flex-start;
	min-width: 430px;
}

.bottom-alert {
	background-color: rgba(45,46,47,.4);
	color: white;
	border-top: .5px solid #2D2E2F; /* black */
}

.bottom-alert .closeBottomError svg {
	fill: white;
}

@media all and (max-width: 1024px) {

	.navButton {
		height: 40px;
	}

}

@media all and (max-width: 1024px) and (orientation:landscape) {
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
		height: 7px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 167, 181,.5); /* rgba(0,0,0,.5);*/
		-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
	}
}

.slds-align_absolute-center {
	display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: auto;
}

.around-small {
	padding: .75rem;
}

.slider {
	width: 100%;
}

.btn-danger,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover {
	color: white;
}

.text-color-error {
	color: $spot-color-red-60;
}
