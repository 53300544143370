@import "shared/spot_tokens";

$evc-color-white: #FFFFFF;

$evc-color-teal: #00A7B5;
$evc-color-light-teal: #E5F6F7;
$evc-color-light-teal2: #88D8DF;
$evc-color-dark-teal: darken( $evc-color-teal, 10% );

$evc-color-green: #83BD40;

$evc-color-red: #dc3545;

// Modalities
$evc-color-snap-background: #FCEACE;
$evc-color-snap: #EF960A;
$evc-color-snap-dark: darken(#EF960A, 10%);

$evc-color-ihd-background: #E6F2D9;
$evc-color-ihd: #83BD40;
$evc-color-ihd-dark: darken(#83BD40, 10%);

$evc-color-black: #2d2e2f;

$evc-color-border: #babdbf;

$evc-color-disabled: #babdbf;

$evc-card-title-background: #F4F6F7;

$evc-color-progress-bar-background: #F4F6F7;
$evc-color-progress-bar-background-dark: darken( $evc-color-progress-bar-background, 10% );

$evc-color-box-shadow: #97999b;

$evc-color-margin: #97999b;

$evc-color-form: #f4f6f7;
$evc-color-form-border: #979797;

$evc-color-light-text: #797A7C;

$evc-scrollbar-thumb-background-color: rgba(0, 167, 181,.5);
