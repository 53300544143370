.evc-layout {
	display: flex;
	flex-direction: column;

	//height:100%;

	height:100vh;

	position: relative;

	max-width: 1024px;
	margin-right: auto;
	margin-left: auto;

}

.evc-layout .evc-body {
	//flex-grow: 1;
	flex: 1;
	min-height: 400px;

	width: 100%;

	overflow: auto;

}

// ::ng-deep {
// 	.keyboard-open .evc-layout {
// 		overflow: auto !important;
// 		-webkit-overflow-scrolling: touch;
// 	}
// }

.evcCard {
	height: 100%;
	width: 100%;
	border: none;
	//padding-top: 10px;
	border-radius: 0px;
	/* border-top: 1px solid #BABDBF; */
	display: flex;
	flex-direction: column;
}


// .main-layout {
// 	width: 100%;

// 	//max-width: 1024px;
// 	margin-left:auto;
// 	margin-right:auto;

// 	flex: 1;

// 	display: -webkit-flex;
// 	display:	-moz-flex;
// 	display:	 -ms-flex;
// 	display:		 flex;

// 	-webkit-flex-direction: column;
// 	-moz-flex-direction: column;
// 	-ms-flex-direction: column;
// 	flex-direction: column;

// 	position:relative;

// }

.x-content-layout {
	/*display:flex;*/
	/*flex-direction:column;*/

	overflow: hidden;

	/*height:100vh;*/
/*
	-webkit-flex: 5;
	-moz-flex: 5;
	-ms-flex: 5;
	flex: 5;

	width: 100%;

	max-width: 1024px;
	margin-left:auto;
	margin-right:auto;

	position:relative
*/
}

// .content-body {

// 	height: 100%;
// 	width: 100%;

// 	max-width: 1024px;
// 	margin-left:auto;
// 	margin-right:auto;


// 	__<<ngThemingMigrationEscapedComment5>>__
// 	__<<ngThemingMigrationEscapedComment6>>__
// 	__<<ngThemingMigrationEscapedComment7>>__
// 	__<<ngThemingMigrationEscapedComment8>>__
// 	position: relative;
// }




.vbox {

	display: -webkit-flex;
	display:	-moz-flex;
	display:	 -ms-flex;
	display:		 flex;

	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.hbox {
	display: -webkit-flex;
	display:	-moz-flex;
	display:	 -ms-flex;
	display:		 flex;

	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

// .page-content-body {
// 	-webkit-flex: 5;
// 	-moz-flex: 5;
// 	-ms-flex: 5;
// 	flex: 5;

// 	width: 100%;
// 	height: 100%;
// 	max-width: 1024px;
// 	margin-left:auto;
// 	margin-right:auto;

// 	display: -webkit-flex;
// 	display:	-moz-flex;
// 	display:	 -ms-flex;
// 	display:		 flex;

// 	-webkit-flex-direction: column;
// 	-moz-flex-direction: column;
// 	-ms-flex-direction: column;
// 	flex-direction: column;
// }

// .view-body {
// 	margin-top: 20px;
// 	display:block;
// 	flex: 1;
// 	overflow: auto;
// 	-webkit-overflow-scrolling: touch;
// 	//position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// }

::ng-deep {
	// .keyboard-open .main-layout {
	// 	overflow: auto !important;
	// 	-webkit-overflow-scrolling: touch;
	// }
	// .keyboard-open .content-body {
	// 	height: auto !important;
	// }
	// .keyboard-open .view-body {
	// 	position: relative !important;
	// 	overflow: visible !important;
	// 	height: auto !important;
	// }
	// .keyboard-open evc-roi {
	// 	position: relative !important;
	// 	height: auto !important;
	// 	overflow: visible !important;
	// }
	// .keyboard-open .router-outlet-wrapper {
	// 	overflow: hidden !important;
	// }
}

.pageHeader {
	width: 100%;

	padding: 0px;/* 10px 20px; */
	background-color: #f4f6f7;
	//margin: 0 0 20px 0;
	border-bottom: 6px solid $evc-color-teal;

	flex-shrink: 0;
}

.pageHeader .appName {
	font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif !important;
	color: #2D2E2F;
	font-size: 26px;
	font-weight: medium;
	width: 100%;
	text-align: center;
}

@media all and (max-width: 1024px) {

	.pageHeader {
		padding: 5px 20px;
		//max-width: 1024px;
		width: 100%;
	}

	.headerLogo {
		height: 20px;
		margin: 20px;
		/* width: 100px; */
	}

}

